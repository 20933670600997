
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        



































































































.tv-price-box,
[class*='tv-price-box--'] {
  @extend %box-shadow;

  position: relative;
  // overflow: hidden;
  width: 100%;

  @include mq(l) {
    max-width: 58rem;
  }
}

.tv-price-box__promo {
  @extend %text-uppercase;

  position: relative;
  z-index: 9;
  padding: $spacing / 4 $spacing / 2;
  color: $white;
  font-family: $ff-alt;
  font-size: 0.9rem;
  letter-spacing: 1px;
  background-color: $c-orange;
  transform: translate($spacing * 2, -50%);
}

.tv-price-box__misc {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tv-price-box-inner {
  padding: $spacing $spacing * 2 $spacing;

  .tv-price-box__promo + & {
    padding-top: $spacing * 0.25;
  }
}

.tv-price-box__from,
.tv-price-box__prev {
  @extend %fw-medium;
  @extend %text-uppercase;

  color: $gray-medium;
  font-size: 0.8rem;
}

.tv-price-box__from {
  color: $c-blue-pale;
  font-family: $ff-alt;
  font-size: 0.9rem;
  letter-spacing: 0.5px;
}

.tv-price-box__prev {
  // prettier-ignore
  @include fluid(font-size,(s: 12px,xl: 18px,));
  position: relative;
  display: table;
  color: $black;
}

.tv-price-box__prev--crossed {
  position: relative;
  display: inline-block;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 100%;
    height: 1px;
    background-color: $black;
    transform: translate(-50%, -50%);
  }
}

.tv-price-box__price {
  // prettier-ignore
  @include fluid(font-size,(s: 12px,xl: 15px,));
  position: absolute;
  left: 0;
  bottom: $spacing * -1.2;
  width: 100%;
  text-align: center;
  font-family: $ff-alt;
  color: $white;

  @include mq(l) {
    position: relative;
    margin-top: $spacing;
    bottom: 0;
    left: 0;
    text-align: left;
    transform: none;

    &::after {
      content: '';
      position: absolute;
      top: -1rem;
      left: 0;
      display: block;
      width: 9rem;
      height: 1px;
      background-color: $white;
    }
  }
}

.tv-price-box__offer-container {
  display: flex;
  flex-direction: column;

  @include mq(l) {
    align-items: baseline;
    flex-direction: row;
  }
}

.tv-price-box__offer {
  // prettier-ignore
  @include fluid(font-size,(s: 18px,xl: 30px,));
  font-family: $ff-alt;
  font-weight: 600;
  color: $white;
}

.tv-price-box__period {
  // prettier-ignore
  @include fluid(font-size,(s: 12px,xl: 18px,));
  font-family: $ff-alt;
  color: $white;

  em {
    @extend %fw-bold;

    color: $c-orange;
    font-style: normal;
  }
}

.tv-price-box__old-price {
  // prettier-ignore
  @include fluid(font-size,(s: 12px,xl: 15px,));
  color: $white;

  @include mq(l) {
    margin-right: 1rem;
  }
}
