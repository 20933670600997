
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        






























































































































































$picture-width: 33.3rem;

.tv-exclusivity {
  position: relative;
  z-index: 1;
  padding: $spacing * 3 $spacing;
  color: $white;
  font-family: $ff-alt;
  opacity: 0;

  @include mq(m) {
    margin: $spacing * 3 0;
    padding: 0;
  }
}

.tv-exclusivity-outer {
  position: relative;
}

.tv-exclusivity__background {
  @include center-xy;

  display: none;
  width: 100%;
  height: 100%;
  background: rgba(196, 196, 196, 0.02);

  img {
    @include image-fit;

    opacity: 0.2;
  }

  .voo-tv-plus & {
    display: block;
    width: 100vw;
  }

  @include mq(m) {
    display: block;
    height: calc(100% - 12rem);
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
  }
}

.tv-exclusivity-inner {
  position: relative;
  overflow: hidden;
  border-radius: 1rem;
  min-height: 80%;

  &::before {
    @include get-all-space;

    content: '';
    z-index: 1;
    display: block;
    width: 100%;
    // padding-top: 161.25%;
    background: linear-gradient(
      180deg,
      rgba(8, 23, 40, 0) 56.25%,
      $c-promo-card 100%
    );
  }

  .voo-tv-plus & {
    display: flex;
    flex-direction: column;

    &::before {
      content: none;
    }
  }

  @include mq($until: m) {
    padding: 0;
  }

  @include mq(m) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: visible;
    max-width: 116rem;
    min-height: 53rem;

    &::before {
      content: none;
    }
  }
}
.tv-exclusivity__content {
  width: 100%;
  padding: 100% $spacing * 0.5 $spacing * 0.5;

  .voo-tv-plus & {
    padding: 0;
  }

  > * {
    position: relative;
    z-index: 1;
  }

  @include mq(m) {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    flex: 1;
    padding: 0 0 0 calc(12rem + #{$picture-width});
    text-align: left;

    .voo-tv-plus & {
      padding: $spacing * 5.5 0 0 50%;
    }
  }
}

.tv-exclusivity__content__logo {
  display: block;
  width: 15rem;
  height: 5rem;
  margin: 0 auto;

  img {
    @include image-fit(contain);
  }

  @include mq(m) {
    display: inline-block;
    height: auto;
    margin: 0 $spacing * 3 0 0;
  }
}

.tv-exclusivity__content__channel {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.2rem;
  margin-top: $spacing * 1.5;

  img {
    width: auto;
    // height: 9rem;
    height: 3.5rem;
    margin-left: $spacing * 0.9;
  }

  @include mq(m) {
    display: block;
    font-size: 1.8rem;
    margin: 0;

    img {
      display: block;
      width: auto;
      height: 6rem;
      margin: 0;
    }
  }
}

.tv-exclusivity__content__subtitle {
  @extend %text-center;

  // prettier-ignore
  @include fluid(font-size, (s: 12px, l: 30px));

  // prettier-ignore
  @include fluid(line-height, (s: 15px, l: 36px));

  // font-size: 1.2rem;
  margin-top: $spacing * 1.5;

  .voo-tv-plus & {
    margin-top: 0;
    font-size: 2.8rem;
    line-height: 3.3rem;
  }

  ::v-deep > p {
    margin: 0;
  }

  @include mq(m) {
    width: 100%;
    margin-top: $spacing * 2;
    text-align: left;

    .voo-tv-plus & {
      margin-top: 0;
      font-size: 5.4rem;
      line-height: 5.6rem;
    }
  }

  @include mq(l) {
    .voo-tv-plus & {
      padding-right: $spacing * 5;
    }
  }
}

.tv-exclusivity__content__text {
  @extend %text-center;

  margin: $spacing 0 $spacing * 1.5;

  @include mq(m) {
    font-size: 1.8rem;
    line-height: 3rem;
    text-align: left;
  }

  @include mq(l) {
    .voo-tv-plus & {
      padding-right: $spacing * 3;
    }
  }
}

.tv-exclusivity__content__picture {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
  margin: 0;
  border-radius: 1rem;

  img {
    @include image-fit;
  }

  .voo-tv-plus & {
    position: relative;
    max-width: 12rem;
    height: 16rem;
    margin: $spacing * 1.5 auto $spacing;
  }

  @include mq(m) {
    width: 40%;
    max-width: $picture-width;
    height: 100%;

    .voo-tv-plus & {
      position: absolute;
      max-width: 39.5rem;
      height: 100%;
      margin: 0;
    }
  }
}

.tv-exclusivity__content__contentPicture {
  width: 100%;
  margin: 0;
  padding: $spacing;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(12px);
  border-radius: 1rem;
  // background: linear-gradient(
  //   180deg,
  //   rgba(8, 23, 40, 0) 56.25%,
  //   $c-promo-card 100%
  // );
  box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.2);

  @include mq(m) {
    margin-top: $spacing * 3;

    .voo-tv-plus & {
      margin-top: $spacing;
    }
  }
}

.tv-exclusivity__content__link {
  @extend %text-center;

  display: block;
  margin: $spacing auto 0;
  color: $white;

  @include mq(l) {
    margin-top: $spacing * 3;
  }
}
