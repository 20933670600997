
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        































.gift {
  @extend %text-center;

  position: relative;
  z-index: 1;
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  flex-shrink: 0;
  overflow: hidden;
  width: var(--tv-gift-width);
  min-height: var(--tv-gift-height);
  padding: $spacing * 0.85;
  border-radius: 1rem;
  will-change: transform, filter;

  &::before {
    @include get-all-space;

    content: '';

    // prettier-ignore
    background:
      linear-gradient(
        180deg,
        rgb(8 23 40 / 0%) 56.25%,
        $c-promo-card 100%
      );
  }

  & + & {
    margin-left: $spacing;
  }

  .usps & {
    @include mq($until: m) {
      flex-direction: row;
      justify-content: stretch;
      align-items: center;
      gap: $spacing;
      width: 100%;
      max-width: none;
      min-height: 0;
      padding: $spacing * 0.5;
      text-align: left;
      background: rgba($white, 0.01);

      &::before {
        content: none;
      }

      &:not(:first-child) {
        margin: $spacing * 0.5 0 0 0;
      }
    }
  }

  @include mq(m) {
    position: absolute;
    z-index: 1;
    transition: 0.8s;
    transition-property: filter, transform, left;

    &.is-center {
      z-index: 2;
      left: 50%;
      transform: translateX(-50%);

      // prettier-ignore
      transition:
        filter 0.8s 0.2s,
        transform 0.8s 0.2s,
        left 0.8s 0.2s,
        z-index 0s 0.2s;
    }

    &.is-left,
    &.is-right {
      filter: blur(5px);
      transform: scale(0.85);
    }

    &.is-left {
      left: -3rem;

      .direction-prev & {
        z-index: -1;
      }
    }

    &.is-right {
      z-index: -1;
      left: calc(100% + 3rem);
      transform: translateX(-100%) scale(0.85);

      .direction-prev & {
        z-index: 1;
      }
    }

    & + & {
      margin-left: 0;
    }
  }
}

.gift__picture {
  @include get-all-space;

  z-index: -1;
  margin: 0;

  img {
    @include image-fit;
  }

  .usps & {
    @include mq($until: m) {
      position: relative;
      flex-shrink: 0;
      overflow: hidden;
      width: 33%;
      aspect-ratio: 3/4;
      border-radius: 1rem;
      transform: none;
    }
  }
}

.gift__title,
.gift__text {
  position: relative;
  z-index: 1;
  font-family: $ff-alt;

  .usps & {
    font-weight: 400;

    @include mq(m) {
      display: none;
    }
  }
}
