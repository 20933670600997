
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        















































































































































































/* stylelint-disable property-no-vendor-prefix, declaration-colon-newline-after */
.tv-extra-header {
  position: relative;
  z-index: 1;
  padding-top: 30vh;

  @include mq(l) {
    padding-top: 28vw;
  }
}

.tv-extra-header-inner {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: $spacing * 2;

  @include mq($until: l) {
    padding-right: 0;
    padding-bottom: $spacing;
    padding-left: 0;
  }

  @include mq(l) {
    flex-flow: row wrap;
    justify-content: space-between;
  }
}

.tv-extra-header__logo-outer {
  position: relative;
  width: 14rem;
  height: 14rem;

  @include mq($until: l) {
    height: 5rem;
    margin: 0 auto;
  }
}

.tv-extra-header__logo {
  @include image-fit(contain);
}

.tv-extra-header__content {
  @include mq(l) {
    width: 50%;
  }
}

.tv-extra-header__title {
  // prettier-ignore
  @include fluid(font-size,(s: 28px,xl: 54px,));

  margin-top: -3rem;
  color: $white;
  font-weight: 400;

  strong {
    font-weight: 700;
  }

  @include mq($until: l) {
    margin-top: 0;
    text-align: center;
  }
}

.tv-extra-hearder_price-box {
  @include mq(l) {
    width: 40%;
  }
}

.tv-extra-header__subtitle-outer {
  width: 80%;
  margin: $spacing * 2 auto 0;
  text-align: center;

  @include mq(l) {
    margin-top: $spacing * 1.5;
  }
}

.tv-extra-header__subtitle {
  // prettier-ignore
  @include fluid(font-size,(s: 15px,xl: 18px,));

  margin-bottom: $spacing;
  color: $white;
  font-weight: 400;

  strong {
    font-weight: 700;
  }
}

.tv-extra-header__subtitle__svg {
  fill: $white;
  height: 2rem;
}

.tv-extra-header__picture-outer {
  @include get-all-space;

  z-index: -1;
  height: 50vh;

  // Fondu sur l'image
  -webkit-mask-image: -webkit-gradient(
    linear,
    left 30%,
    left 90%,
    from(rgba($black, 1)),
    to(rgba($black, 0))
  );
  mask-image: -webkit-gradient(
    linear,
    left 30%,
    left 90%,
    from(rgba($black, 1)),
    to(rgba($black, 0))
  );

  @include mq(l) {
    height: 100%;
    -webkit-mask-image: -webkit-gradient(
      linear,
      left 30%,
      left 70%,
      from(rgba($black, 1)),
      to(rgba($black, 0))
    );
    mask-image: -webkit-gradient(
      linear,
      left 30%,
      left 70%,
      from(rgba($black, 1)),
      to(rgba($black, 0))
    );

    &.no-video::before {
      @include get-all-space;

      content: '';
      z-index: 1;
      background-image: linear-gradient(
        1deg,
        transparent 60%,
        rgba($black, 0.7) 100%,
        $black 140%
      );
    }
  }
}

.tv-extra-header__picture {
  @include image-fit;
}
