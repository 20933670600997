
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        


















































































































































































































































































.tv-posters {
  position: relative;
  z-index: 1;
  margin: $spacing * 2 0;

  @include mq(l) {
    margin: $spacing * 4 0 $spacing * 6;
  }
}

.tv-posters-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.tv-posters__title {
  @extend %text-center;

  // prettier-ignore
  @include fluid(font-size,(s: 28px,xl: 54px,));

  max-width: 30rem;
  margin: 0 auto;
  color: $white;
  font-weight: 400;

  strong {
    font-weight: 700;
  }

  @include mq(l) {
    max-width: 80rem;
  }
}

.tv-posters__subtitle {
  @extend %text-center;
  // prettier-ignore
  @include fluid(font-size,(s: 15px,xl: 18px,));

  color: $white;
  font-weight: 400;

  strong {
    font-weight: 700;
    text-align: center;
  }
}

.tv-posters__link {
  @extend %text-center;
}

.tv-posters__sliders,
.tv-posters__list {
  margin-top: $spacing * 1.5;

  @include mq(l) {
    margin-top: $spacing * 2.5;
  }
}

// prevent too much empty space on xxxl
.tv-posters__sliders {
  max-width: 144rem;
  margin-right: auto;
  margin-left: auto;
}

.tv-posters__slider,
[class*='tv-posters__slider--'] {
  & + & {
    display: flex;
    justify-content: flex-end;
    margin-top: 2rem;
  }
}

.tv-posters__list-inner {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: $spacing * 0.5;

  @include mq(l) {
    justify-content: center;
    gap: $spacing * 1.5;
  }
}

.tv-posters__slider-inner {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  .is-single & {
    justify-content: center;
  }
}

.tv-posters__item {
  // prettier-ignore
  @include fluid(width,(s: 106px, l: 170px,));

  flex-shrink: 0;

  .horizontal & {
    // prettier-ignore
    @include fluid(width,(s: 133px, l: 300px,));
  }

  .tv-posters__list-inner & {
    width: calc(96% / 2);
    margin-left: 0 !important;

    &:first-child {
      width: 100%;
    }
  }

  & + & {
    margin-left: 1rem;

    @include mq(l) {
      margin-left: 2rem;
    }
  }

  @include mq(m) {
    .tv-posters__list-inner & {
      width: calc(calc(100% - #{$spacing * 3}) / 3) !important;
    }
  }
}

.tv-posters__item__picture-outer {
  position: relative;
  overflow: hidden;
  height: auto;
  aspect-ratio: 3/4;
  border-radius: 1rem;

  .horizontal & {
    aspect-ratio: 4/3;
  }
}

.tv-posters__item__picture {
  @include image-fit;
}

.tv-posters__item__title {
  margin: 0;
  color: $white;
  font-size: 1.2rem;
  font-weight: 300;
  line-height: 2rem;
}
