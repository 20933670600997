
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        









































































.tv-extra {
  @include mq($until: l) {
    padding: $spacing * 4.5 0;
  }
}

.conditions-container {
  position: relative;
  z-index: 1;
}

pre {
  margin: $spacing;
  padding: $spacing * 0.5;
  border: 1px dotted $black;
  background: $white;
}
