
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        







































































































































$content-padding-mobile: 2rem;

.content__inner {
  position: relative;
  z-index: 1;
  height: 100%;
  padding: $content-padding-mobile;
  display: flex;
  flex-direction: column;

  @include mq(l) {
    padding: 4rem 5rem;
  }
}

.content__close {
  width: 2.5rem;
  height: 2.5rem;
  padding: 0;
  background: transparent;
  border-width: 0;
  margin-bottom: 2rem;

  @include mq(l) {
    position: absolute;
    z-index: 1;
    top: 3.3rem;
    right: 3.3rem;
    margin-bottom: 0;
  }

  svg {
    stroke: white;
    fill: none;
    width: 100%;
    height: 100%;
  }

  .content__close__mobile {
    display: block;

    @include mq(l) {
      display: none;
    }
  }

  .content__close__desktop {
    display: none;

    @include mq(l) {
      fill: currentColor;
      stroke: none;
      display: block;
    }
  }
}

.content__header {
  position: relative;
  display: flex;
  width: fit-content;
  max-width: 55%;
}

.content__logo {
  @include center-y;

  max-width: 12rem;
  right: -$spacing;
  transform: translate(100%, -50%);
}

.content__title {
  margin: 0 0 1.5rem;
}

.content__channel {
  // prettier-ignore
  @include fluid(font-size,(s: 12px,xl: 14px,));
  @extend %text-uppercase;

  color: $c-blue-pale;
  font-weight: 400;
}

.content__tab-content {
  margin-top: $spacing * 2;
  margin-bottom: 4rem;
  padding-right: $spacing * 0.5;

  dt,
  dd {
    display: inline;
    line-height: 2rem;
  }

  dt {
    @extend %fw-bold;
  }

  dd {
    margin-left: 0.5em;
  }

  ::v-deep {
    > *:first-child {
      margin-top: 0;
    }
  }
}

.content__tab__link {
  margin-top: $spacing;

  @include mq(l) {
    margin-top: $spacing * 2;
  }
}
