
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        













































































































































































































































































































































































.tv-gifts,
[class*='tv-gifts--'] {
  --tv-gift-width: 32rem;
  --tv-gift-height: 46rem;

  position: relative;
  z-index: 2;
  overflow: hidden;
  width: 100%;
  margin-top: $spacing * 2;
  margin-bottom: $spacing * 2;
  color: $c-white-dark;

  &.usps {
    margin-top: 0;
    margin-bottom: 0;
  }

  @include mq(m) {
    margin-top: $spacing * 2.5;
    margin-bottom: $spacing * 2.5;
  }
}

.tv-gifts-wrapper {
  .usps & {
    @include mq($until: m) {
      padding-top: 0 !important;
    }
  }
}

.tv-gifts-inner {
  position: relative;

  @include mq(m) {
    display: flex;
    flex-direction: column;
    min-height: var(--tv-gift-height);
    padding: $spacing * 2.5 0 $spacing * 2 65%;
  }

  @include mq(l) {
    padding-left: 70%;
  }
}

.tv-gifts__title {
  @extend %text-center;

  max-width: 20em;
  margin: 0 auto $spacing;

  .usps & {
    position: relative;
    display: flex;
    align-items: center;
    flex: 1;
    width: 100%;
    margin: 0 auto 0 0;
    font-size: 3rem;
    font-weight: 400;
    text-align: left;

    @include mq($until: m) {
      display: none;
    }
  }

  @include mq(m) {
    max-width: none;
    margin: 0 auto 0 0;
    text-align: left;
  }
}

.tv-gifts__slidy-container {
  display: none;
  width: 100%;

  @include mq(m) {
    display: block;
    order: 10;
  }
}

.tv-gifts__slidy {
  display: flex;
  width: 100%;
  margin: 0;
  padding: 0 0 0 calc(50% - var(--tv-gift-height, 0) * 0.5);
  list-style-type: none;

  .usps & {
    @include mq($until: m) {
      display: block;
      height: auto;
      padding: 0;
      touch-action: auto;
    }
  }

  @include mq(m) {
    @include center-y;

    left: 0;
    width: 60%;
    padding-left: 0;
  }
}

.tv-gifts__carousel {
  margin-block-end: 2rem;

  @include mq(m) {
    display: none;
  }
}

.tv-gifts__link {
  display: flex;
  width: fit-content;
  margin: auto;

  @include mq(m) {
    margin-left: 0;
  }
}

::v-deep {
  .tv-gifts__slidy-outer {
    @include mq(m) {
      display: flex;
      align-items: center;
      margin-top: $spacing * 2;
    }
  }

  .tv-gifts__slidy-controls {
    @include mq($until: m) {
      display: none;
    }
  }

  .tv-gifts__slidy-nav {
    margin: $spacing * 0.5 auto $spacing;

    @include mq(m) {
      margin: 0 0 0 $spacing;
    }
  }
}
