
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        










































































































































































































.tv-channels-list {
  position: relative;
  z-index: 1;
}

.tv-channels-list-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.tv-channels-list__title {
  @extend %text-center;

  // prettier-ignore
  @include fluid(font-size,(s: 28px,xl: 54px,));

  max-width: 30rem;
  margin: 0 auto;
  color: $white;
  font-weight: 400;

  strong {
    font-weight: 700;
  }

  @include mq(l) {
    max-width: 80rem;
  }
}

.tv-channels-list__subtitle {
  @extend %text-center;

  // prettier-ignore
  @include fluid(font-size,(s: 15px,xl: 18px,));

  margin-bottom: 0;
  color: $white;
  font-weight: 400;

  strong {
    font-weight: 700;
    text-align: center;
  }
}

.tv-channels-list__list {
  @include mq(l) {
    box-shadow: $box-shadow-lvl-2;
  }
}

.tv-channels-list__list-inner {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: $spacing * 1.5;

  @include mq(l) {
    margin-top: $spacing * 3;
    padding-top: $spacing * 3;
    padding-bottom: $spacing * 3;
  }
}

.tv-channels-list__category {
  width: 100%;
  margin-bottom: $spacing * 2;

  @include mq(xxl) {
    &:not(&.tv-channels-list__category-simple) {
      width: 38%;

      &:nth-child(even) {
        .tv-channels-list__items {
          &::before {
            @include center-y;

            content: '';
            left: -5%;
            display: block;
            width: 1px;
            height: 6.5rem;
            background-color: $white;
          }
        }
      }

      &:nth-child(odd) {
        width: 58%;
      }
    }
  }
}

.tv-channels-list__items {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1%;
}

.tv-channels-list__category__title {
  @extend %text-uppercase;

  // prettier-ignore
  @include fluid(font-size,(s: 15px,xl: 18px,));

  width: 100%;
  margin: 0 0 $spacing;
  color: $white;
  font-weight: 400;
}

.tv-channels-list__item-outer {
  @extend %box-shadow;

  position: relative;
  overflow: hidden;
  width: 100%;
  margin: 0 1%;
  margin-top: $spacing * 0.5;
  margin-bottom: $spacing * 0.5;
  border-radius: 1rem;

  // width: calc(96% / 2);

  @include mq(xxs) {
    width: calc(96% / 2);
  }

  @include mq(l) {
    width: calc(94% / 3);
    margin-top: $spacing;
    margin-bottom: $spacing;

    .tv-channels-list__category-simple & {
      width: calc(96% / 5.4) !important;
    }
  }

  @include mq(xxl) {
    .tv-channels-list__category:nth-child(even) & {
      width: calc(96% / 2);

      .tv-channels-list__category-simple & {
        width: calc(96% / 5.4) !important;
      }
    }
  }
}

.tv-channels-list__item-inner {
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
  border-radius: 1rem;
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: $white;
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.3s ease-out;
  }

  &:hover {
    .tv-channels-list__item__title,
    .tv-channels-list__item__channel {
      color: $c-midnight-darker;
    }

    &::before {
      transform: scaleX(1);
    }
  }
}

.tv-channels-list__item__picture-outer {
  position: relative;
  flex-shrink: 0;
  width: 7rem;
  height: 7rem;
  background-color: $white;
  border-radius: 1rem;

  @include mq(l) {
    width: 10rem;
    height: 10rem;
  }
}

.tv-channels-list__item__picture {
  @include image-fit(contain);

  padding: $spacing * 0.5;
}

.tv-channels-list__item__content {
  padding: 0 1rem;

  @include mq(l) {
    padding: 0 1.5rem;
  }
}

.tv-channels-list__item__title {
  // prettier-ignore
  @include fluid(font-size,(s: 15px,xl: 18px,));

  margin: 0;
  color: $white;
  font-weight: 600;
  transition: color 0.5s ease-out;
}

.tv-channels-list__item__channel {
  @extend %text-uppercase;

  // prettier-ignore
  @include fluid(font-size,(s: 12px,xl: 14px,));

  color: $c-blue-pale;
  font-weight: 400;
  transition: color 0.5s ease-out;
}

.speedlines {
  position: absolute;
  top: -10%;
  left: -12%;
  width: 200px;
  height: 200px;

  @include mq(l) {
    left: 0;
    width: 300px;
    height: 300px;
  }
}
